export const storeProducts = [
    {
        "id": "000000006",
        "name": "Бетонна суміш В-20 (М-250) Р-3",
        "description": "Використовується при виготовленні монолітних фундаментів, обмосток, парканів, стін, площадок,сходів.",
        "price": "2592",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-20",
            "Рухливість": "Р-3",
            "Марка": "М-250",
            "Морозостійкість": "F75"
        }
    },
    {
        "id": "000000016",
        "name": "Бетонна суміш В-20 (М-250) Р-4",
        "description": "",
        "price": "2739",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": true,
        "params": {
            "Клас": "В-20",
            "Рухливість": "Р-4",
            "Марка": "М-250",
            "Морозостійкість": "F75"
        }
    },
    {
        "id": "000000017",
        "name": "Бетонна суміш В-7,5 (М-100) Р-3",
        "description": "Дрібнозерниста бетонна суміш, застовується під час попередніх робіт по монтажу фундаменту.",
        "price": "2349",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-7,5",
            "Рухливість": "Р-3",
            "Марка": "М-100",
            "Морозостійкість": "F50"
        }
    },
    {
        "id": "000000018",
        "name": "Бетонна суміш В-10 (М-150) Р-3",
        "description": "Дрібнозерниста бетонна суміш, застосовується в тонкостінних конструкціях для заповнення стиків між елементами, формування гідроізоляційного шару. ",
        "price": "2394",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-10",
            "Рухливість": "Р-3",
            "Марка": "М-150",
            "Морозостійкість": "F50"
        }
    },
    {
        "id": "000000019",
        "name": "Бетонна суміш В-15 (М-200) Р-3",
        "description": "Використовується при виготовленні доріжок, відмосток, бетонних сходів, площадок, тощо. Застосовують в  індивідуальному будівництві, стрічкових фундаментах, плитних фундаментах та ін.",
        "price": "2499",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-15",
            "Рухливість": "Р-3",
            "Марка": "М-200",
            "Морозостійкість": "F75"
        }
    },
    {
        "id": "000000020",
        "name": "Бетонна суміш В-15 (М-200) Р-4",
        "description": "",
        "price": "2631",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-15",
            "Рухливість": "Р-4",
            "Марка": "М-200",
            "Морозостійкість": "F75"
        }
    },
    {
        "id": "000000021",
        "name": "Бетонна суміш В-22,5 (М-300) Р-3",
        "description": "Універсальний бетон, практично для будь-яких будівельних потреб.",
        "price": "2760",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-22,5",
            "Рухливість": "Р-3",
            "Марка": "М-300",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000022",
        "name": "Бетонна суміш В-22,5 (М-300) Р-4",
        "description": "",
        "price": "2865",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-22,5",
            "Рухливість": "Р-4",
            "Марка": "М-300",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000023",
        "name": "Бетонна суміш В-25 (М-350) Р-3",
        "description": "Використовується при виготовленні монолітних фундаментів, паль, ростверків, плит перекриттів, колон, ригелів, балок з великою величиною прольоту, монолітних стін, промислових підлог, чаш басейнів та інших відповідальних конструкцій.",
        "price": "2841",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-25",
            "Рухливість": "Р-3",
            "Марка": "М-350",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000024",
        "name": "Бетонна суміш В-25 (М-350) Р-4",
        "description": "",
        "price": "2931",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-25",
            "Рухливість": "Р-4",
            "Марка": "М-350",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000025",
        "name": "Бетонна суміш В-30 (М-400) Р-3",
        "description": "Використовується в монолітно-каркасному будівництві при зведенні житлових будинків підвищеної поверховості. Застосовується для мостових конструкцій, гідротехнічних споруд, конструкцій мають особливі вимоги.",
        "price": "2940",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-30",
            "Рухливість": "Р-3",
            "Марка": "М-400",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000026",
        "name": "Бетонна суміш В-30 (М-400) Р-4",
        "description": "",
        "price": "3066",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-30",
            "Рухливість": "Р-4",
            "Марка": "М-400",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000027",
        "name": "Бетонна суміш В-35 (М-450) Р-4",
        "description": "",
        "price": "3252",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-35",
            "Рухливість": "Р-4",
            "Марка": "М-450",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000028",
        "name": "Бетонна суміш В-35 (М-450) Р-3",
        "description": "Використовують при будівництві мостів, метро,тунелів, дамб, гребель, гідротехнічних споруд, перекриттів у висотному будівництві, несучих конструкцій великих промислових будівель, що піддаються високим навантаженням.",
        "price": "3051",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-35",
            "Рухливість": "Р-3",
            "Марка": "М-450",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000029",
        "name": "Бетонна суміш В-40 (М-500) Р-3",
        "description": "Використовують при будівництві мостів і провідних опорних конструкцій; басейнів і аквапарків; дамб, гребель; підземних і навколоводних споруд, бункерів різного типу; атомних станцій, аеродромів.",
        "price": "3291",
        "groupName": "Бетонна суміш Р3 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-40",
            "Рухливість": "Р-3",
            "Марка": "М-500",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000030",
        "name": "Бетонна суміш В-40 (М-500) Р-4",
        "description": "",
        "price": "3444",
        "groupName": "Бетонна суміш Р4 (для сайту)",
        "isGroup": false,
        "params": {
            "Клас": "В-40",
            "Рухливість": "Р-4",
            "Марка": "М-500",
            "Морозостійкість": "F200"
        }
    },
    {
        "id": "000000213",
        "name": "Цементний розчин  М-75 Р-8",
        "description": "",
        "price": "1554",
        "groupName": "Продукция",
        "isGroup": false,
        "params": {
            "": ""
        }
    }
]

export const goodsForTest = [
    {
        "id": "000000013",
        "name": "Пісок будівельний",
        "description": "Піски́ будіве́льні (рос. пески строительные, англ. construction sands, quarry sands, mason's sands, mortar sands, нім. Sande m pl für Bauzwecke) — найбільша функціональна група пісків, що використовуються для всіх видів бетонів і будівельних розчинів, а також для облаштування доріг та інших будівельних робіт. \n"
            + "Будівельний пісок - згідно ГОСТ 8736-2014 будівельний пісок - це неорганічний сипучий матеріал з крупністю зерен до 5 мм, що утворився в результаті природного руйнування скельних гірських порід і отримується при розробці піщаних і піщано-гравійних родовищ без використання або з використанням спеціального збагачувального обладнання.\n"
            + "Піски будівельні поділяють на природні (природні і збагачені) і штучні, що отримують спеціальним дробленням скельних гірських порід. До природних відносять піски крупністю 0,05-5 мм.\n"
            + "Якість будівельних пісків характеризується їх ґранулометричним (зерновим) складом, вмістом пилувато-глинистих частинок, органічних і шкідливих домішок; середньою густиною зерен піску; вологістю.\n"
            + "За крупністю зерен природні будівельні піски поділяються на групи: крупні, середні, дрібні і дуже дрібні.\n"
            + "Шкідливі домішки мінералів і порід (гіпс, пірит, слюда та інші), а також органічних речовин, як правило, обмежуються або не допускаються.\n"
            + "Серед родовищ пісків за генезисом виділяються алювіальні, льодовикові, морські, озерні, елювіальні, делювіальні, пролювіальні і еолові.\n"
            + "Пісок підрозділяється на: природний і збагачений з відсівів дроблення. Пісок з відсівів дроблення характеризується межею міцності вихідної гірської породи при стиску в водонасиченому стані.",
        "price": "123",
        "groupName": "Товар для сайту",
        "isGroup": false,
        "params": {
            "": ""
        }
    },
    {
        "id": "000000158",
        "name": "Щебінь фр.5*20",
        "description": "",
        "price": "1657",
        "groupName": "Товар для сайту",
        "isGroup": false,
        "params": {
            "": ""
        }
    }
]

export const servicesForTest = [
    {
        "id": "000000140",
        "name": "Послуги автобетононасоса",
        "description": "Автобетононасос – це бетононасос, поставлений на шасі тягача. Дані бетононасоси призначені для експлуатації на тих будівельних майданчиках, де ускладнений під'їзд до об'єкта.\n Головна відмінність автобетононасоса від стаціонарного – його мобільність і наявність стріли. Він без проблем може пересуватися по будівництву або ж обслужити кілька будівельних майданчиків, при цьому подаючи бетон в будь-яку точку обслуговування. Продуктивність 90 м³ / год.",
        "price": "150",
        "groupName": "Послуги для сайту",
        "isGroup": false,
        "params": {
            "": ""
        }
    },
    {
        "id": "000000161",
        "name": "Подача бетону транспортерною стрічкою",
        "description": "",
        "price": "150",
        "groupName": "Послуги для сайту",
        "isGroup": false,
        "params": {
            "": ""
        }
    }
]



export const detailProduct = {
  id: 1,
  name: "Бетонна суміш В20 (М-250) Р-3",
  price: 1200,
  img: "img/beton2.jpg",
  description: "Бетон М-250, В-20, Р-3, F-200, W-6, СМ практичний матеріал, який часто використовується в сучасному будівництві. Продукція годиться для заливки фундаменту монолітних будівель, для прокладання дорожнього полотна. Відносно ціни, то вона цілком співвідносна з якістю.",
  inCart: false,
  quantity: 0,
    params: {},
}


export const defaultUser = {
  id: 0,
  firstName: "",
  lastName: "",
  loggedIn: false,
  props: {
    email: "",
    phone: "",
  }
}
