import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import {BrowserRouter as Router} from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import './index.css';
import App from './App';
// import Placeholder from './components/Placeholder';
import productReducer, {getProductsAsync} from './store/product/actions'
import userReducer from './store/user/actions'
import displayReducer from './store/display/actions'
import goodsReducer, {getGoodsAsync} from './store/goods/actions'
import servicesReducer, {getServicesAsync} from './store/services/actions'


import reportWebVitals from './reportWebVitals';


const rootReducer = combineReducers({
  user: userReducer,
  goods: goodsReducer,
  product: productReducer,
  display: displayReducer,
  services: servicesReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))
store.dispatch(getServicesAsync())
store.dispatch(getProductsAsync())
store.dispatch(getGoodsAsync())


// ReactDOM.render(
//   <React.StrictMode>
//     <Placeholder/>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
