

import {getGoodsRequest} from '../../services/1ChttpService'
import {goodsForTest} from '../../data'

const defaultGoods = process.env.NODE_ENV === "development" ? goodsForTest: []
const defaultState = {goods: defaultGoods, totalInCart: 0}

const GET_GOODS = "GET_GOODS"
const ADD_TO_CART = "ADD_TO_CART"
const DELETE_FROM_CART = "DELETE_FROM_CART"
const CHANGE_QUANTITY = "CHANGE_QUANTITY"
const SET_GOODS_IMAGE = "SET_GOODS_IMAGE"


export const addToCart = (id, count) => ({type: ADD_TO_CART, id: id, count: count})
export const getGoods = (goods) => ({type: GET_GOODS, goods: goods})
export const deleteFromCart = (id) => ({type: DELETE_FROM_CART, id: id})
export const changeQuantity = (id, newQuantity) => ({type: CHANGE_QUANTITY, id: id, quantity: newQuantity})
export const setGoodsImage = (imageData, imageGuid) => ({type: SET_GOODS_IMAGE, imageData: imageData, imageGuid: imageGuid})

export const getGoodsAsync = () => {
  return (dispatch, getState) => {
    getGoodsRequest(dispatch)
  }
}

export default function goodsReducer(state = defaultState, action){
  let newState = {}
  switch (action.type) {
    case GET_GOODS:
      return getNewState(state, {goods: action.goods})
    case ADD_TO_CART:
      newState = getNewState(state, {})
      newState.totalInCart = 0
      newState.goods = state.goods.map(goods => {
        if(goods.id === action.id){
          goods.inCart = true
          goods.quantity = action.count
        }
        newState.totalInCart += goods.inCart ? 1: 0
        return goods
      })

      return newState
    case DELETE_FROM_CART:
      newState = getNewState(state, {})
      newState.totalInCart = 0
      newState.goods = state.goods.map(product => {
        if(product.id === action.id){
          product.inCart = false
          product.quantity = 0
        }
        newState.totalInCart += product.inCart ? 1: 0
        return product
      })
      return newState
    case CHANGE_QUANTITY:
      newState = getNewState(state, {})
      newState.goods = state.goods.map(product => {
        if(product.id === action.id){
          product.quantity = action.quantity < 0 ? -action.quantity: action.quantity
        }
        return product
      })
      return newState
    case SET_GOODS_IMAGE:
      newState = getNewState(state, {})
      newState.goods = newState.goods.map(item => {
        if (item.imageGuid === action.imageGuid){
          item.img = action.imageData.img
        }
        return item
      })
      return newState
    default:
      return state
  }

}

const getNewState = (state, change) => {
  const newState = {}
  Object.assign(newState, state)
  for(const [key, value] of Object.entries(change)){
    newState[key] = value
  }
  return newState
}
