
import {servicesForTest} from '../../data'
import {getServicesRequest} from '../../services/1ChttpService'

const defaultServices = process.env.NODE_ENV === "development" ? servicesForTest: []

const defaultState = {services: defaultServices, totalInCart: 0}

const GET_SERVICES = "GET_SERVICES"
const ADD_TO_CART = "ADD_TO_CART"
const DELETE_FROM_CART = "DELETE_FROM_CART"
const CHANGE_QUANTITY = "CHANGE_QUANTITY"
const SET_SERVICE_IMAGE = "SET_SERVICE_IMAGE"


export const addToCart = (id, count) => ({type: ADD_TO_CART, id: id, count: count})
export const getServices = (services) => ({type: GET_SERVICES, services: services})
export const deleteFromCart = (id) => ({type: DELETE_FROM_CART, id: id})
export const changeQuantity = (id, newQuantity) => ({type: CHANGE_QUANTITY, id: id, quantity: newQuantity})
export const setServiceImage = (imageData, imageGuid) => ({type: SET_SERVICE_IMAGE, imageData: imageData, imageGuid: imageGuid})

export const getServicesAsync = () => {
  return (dispatch, getState) => {
    getServicesRequest(dispatch)
  }
}

export default function goodsReducer(state = defaultState, action){
  let newState = {}
  switch (action.type) {
    case GET_SERVICES:
      return getNewState(state, {services: action.services})
    case ADD_TO_CART:
      newState = getNewState(state, {})
      newState.totalInCart = 0
      newState.services = state.services.map(goods => {
        if(goods.id === action.id){
          goods.inCart = true
          goods.quantity = action.count
        }
        newState.totalInCart += goods.inCart ? 1: 0
        return goods
      })

      return newState
    case DELETE_FROM_CART:
      newState = getNewState(state, {})
      newState.totalInCart = 0
      newState.services = state.services.map(product => {
        if(product.id === action.id){
          product.inCart = false
          product.quantity = 0
        }
        newState.totalInCart += product.inCart ? 1: 0
        return product
      })
      return newState
    case CHANGE_QUANTITY:
      newState = getNewState(state, {})
      newState.services = state.services.map(product => {
        if(product.id === action.id){
          product.quantity = action.quantity < 0 ? -action.quantity: action.quantity
        }
        return product
      })
      return newState
    case SET_SERVICE_IMAGE:
      newState = getNewState(state, {})
      newState.services.map(item => {
        if (item.imageGuid === action.imageGuid){
          item.img = action.imageData.img
        }
        return item
      })
      return newState
    default:
      return state
  }

}


const getNewState = (state, change) => {
  const newState = {}
  Object.assign(newState, state)
  for(const [key, value] of Object.entries(change)){
    newState[key] = value
  }
  return newState
}
