import {storeProducts} from '../../data'
import {getProductsRequest} from '../../services/1ChttpService'

const SET_SELECTED = "SET_SELECTED"
const ADD_TO_CART = "ADD_TO_CART"
const DELETE_FROM_CART = "DELETE_FROM_CART"
const CHANGE_QUANTITY = "CHANGE_QUANTITY"
const GET_PRODUCTS = "GET_PRODUCTS"
const SET_PRODUCT_IMAGE = "SET_PRODUCT_IMAGE"
const CLEAR_CART = "CLEAR_CART"
const SET_SERVICES = "SET_SERVICES"
const SET_SERVICE_CHECHED = "SET_SERVICE_CHECHED"

const defaultProducts = process.env.NODE_ENV === "development" ? storeProducts: []

const defaultState = {products: defaultProducts, selected: null, totalInCart: 0}

export const handleDetail = (id) => ({type: SET_SELECTED, id: id})
export const addToCart = (id) => ({type: ADD_TO_CART, id: id})
export const deleteFromCart = (id) => ({type: DELETE_FROM_CART, id: id})
export const changeQuantity = (id, newQuantity) => ({type: CHANGE_QUANTITY, id: id, quantity: newQuantity})
export const getProducts = (products) => ({type: GET_PRODUCTS, products: products})
export const setProductImage = (imageData, imageGuid) => ({type: SET_PRODUCT_IMAGE, imageData: imageData, imageGuid: imageGuid})
export const setProductServices = (services) => ({type: SET_SERVICES, services: services})
export const clearCart = () => ({type: CLEAR_CART})
export const setServiceChecked = (productId, serviceId, checked) => ({type:SET_SERVICE_CHECHED, productId: productId, serviceId: serviceId, checked: checked})

export const getProductsAsync = () => {
  return (dispatch, getState) => {
    getProductsRequest(dispatch)
  }
}

export default function productReducer(state = defaultState, action) {
  let newState = getNewState(state)
  switch(action.type){
    case SET_SELECTED:
      newState.selected = action.id
      return newState
    case ADD_TO_CART:
      newState.totalInCart = 0
      newState.products = state.products.map(product => {
        if(product.id === action.id){
          product.inCart = true
          product.quantity = !product.quantity ? 1: product.quantity
        }
        newState.totalInCart += product.inCart ? 1: 0
        return product
      })
      return newState
    case DELETE_FROM_CART:
      newState.totalInCart = 0
      newState.products = state.products.map(product => {
        if(product.id === action.id){
          deleteProductFromCart(product)
        }
        newState.totalInCart += product.inCart ? 1: 0
        return product
      })
      return newState
    case CHANGE_QUANTITY:
      newState.products = state.products.map(product => {
        if(product.id === action.id){
          product.quantity = action.quantity < 0 ? -action.quantity: action.quantity
        }
        return product
      })
      return newState
    case GET_PRODUCTS:
      newState.products = action.products
      action.products = assignServicestoProducts(action.products, newState.services)
      return newState
    case SET_PRODUCT_IMAGE:
      newState.products = newState.products.map(item => {
        if (item.imageGuid === action.imageGuid){
          item.img = action.imageData.img
        }
        return item
      })
      return newState
    case CLEAR_CART:
      newState.products = newState.products.map(product => {
        deleteProductFromCart(product)
        return product
      })
      newState.totalInCart = 0
      return newState
    case SET_SERVICES:
      newState.services = action.services
      newState.products = assignServicestoProducts(newState.products, action.services)
      return newState
    case SET_SERVICE_CHECHED:
      newState.products = state.products.map(product => {
        if(product.id === action.productId){
          product.services = product.services.map(service => {
            if(service.id === action.serviceId){
              service.checked = action.checked
            }else{
              service.checked = false
            }
            return service
          })
        }
      return product
    })
      return newState
    default:
      return state
  }
}

const deleteProductFromCart = (product) => {
  product.inCart = false
  product.quantity = 0
  product.services = product.services.map(service => {
    service.checked = false
    return service
  })
  return product
}


const assignServicestoProducts = (products, services = undefined) => {
  const servicesToAssign = services ? services.filter(item =>
    item.params.hasOwnProperty("Опція продукції") && item.params["Опція продукції"] == "Так"
  ): []

  return products.map(product => {
    product.services = servicesToAssign.map(service => {

      return {id: service.id, name: service.name, price: service.price, checked: false}
    })
    return product
  })
}

const getNewState = (state) => {
  const newState = {}
  Object.assign(newState, state)
  return newState
}
