import {getProducts, setProductImage, setProductServices} from "../store/product/actions"
import {getGoods, setGoodsImage} from "../store/goods/actions"
import {getServices, setServiceImage} from "../store/services/actions"
import {setQuickCall} from "../store/display/actions"

import config from '../config.json'

// const baseURL = process.env.NODE_ENV === "development" ? config.baseURLtest :config.baseURL;
export const sklobudresursUrl = "https://sklobudresurs.com/"
// export const sklobudresursUrl = "http://localhost:3000/"
const baseURL = sklobudresursUrl + "api/";
// const baseURL = process.env.NODE_ENV === "development" ? config.baseURLtest : window.location.origin + "/storage/";


const makeFetchRequest = (method, address, callback, urlParams = {}) => {
  let targetUrl = baseURL.concat(address);
  const url = new URL(targetUrl)
  Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))

  fetch(url, {
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;X-www-form-urlencoded',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',

    },
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
     console.error(error);
  });
}

export const postPhoneRequest = (dispatch, phone) => {
  const callback = (json) => {
    console.log(json)
    if(json.statusMessage === "OK"){
      console.log("setQuickCall(false)")
      dispatch(setQuickCall(false))
    }
  }

  makeFetchRequest("GET", "telegram/sendMessage", callback, {text: "Передзвонити " + phone});
}

export const getProductsRequest = (dispatch) => {

  const callback = (json) => {
    json.data.map(item => {
      if(!item.hasOwnProperty("imageGuid") ) return item;
      getImage(dispatch, item.imageGuid, setProductImage)
      return item
    })
    json.data.map(product => {
      product.services = []
      return product
    })
    dispatch(getProducts(json.data))
  }

  makeFetchRequest("GET", "products", callback);
}

export const getGoodsRequest = (dispatch) => {

  const callback = (json) => {
    json.data.map(item => {
    if(!item.hasOwnProperty("imageGuid") ) return item;
      getImage(dispatch, item.imageGuid, setGoodsImage)
      return item
    })
    dispatch(getGoods(json.data))
  }

  makeFetchRequest("GET", "goods", callback);
}

export const getServicesRequest = (dispatch) => {

  const callback = (json) => {
    json.data.map(item => {
      item.services = []
    if(!item.hasOwnProperty("imageGuid")) return item;
      getImage(dispatch, item.imageGuid, setServiceImage)
      return item
    })
    dispatch(setProductServices(json.data))
    dispatch(getServices(json.data))
  }

  makeFetchRequest("GET", "services", callback);
}

export const postUserRequest = (dispatch, userData, loginCallback) => {
  let targetUrl = baseURL.concat('user');
  const url = new URL(targetUrl)
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
    body: JSON.stringify({type: "Sign_up", data: userData})
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    dispatch(loginCallback(json.data.login, json.data.pwd, true))
  })
  .catch((error) => {
     console.error(error);
  });
}

export const getUserRequest = (dispatch, userData, loginCallback) => {
  let targetUrl = baseURL.concat('user');
  const url = new URL(targetUrl)
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
    body: JSON.stringify({type: "Sign_in", data: userData})
  })
  .then((response) => {

      return response.json()
  })
  .then((json) => {
    dispatch(loginCallback(json.data))
  })
  .catch((error) => {
     console.error(error);
  });
}

export const getImage = (dispatch, imageGuid, dispatchFunction) => {
  
  if(imageGuid === "" || imageGuid === null || imageGuid === "null") return

  let targetUrl = baseURL.concat('image');
  const url = new URL(targetUrl)

  const params = {ImageGUID: imageGuid}
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    // const getImagefromBase64 = (base64String) =>{
    //   let base64Icon = 'data:image/jpeg;base64,' + base64String;
    //   return base64Icon
    // }

    if(!json.hasOwnProperty("loaded") || !json.loaded) return;

    // json.data.img = getImagefromBase64(json.data.img)
    dispatch(dispatchFunction(json.data, imageGuid))
  })
  .catch((error) => {
     console.error(error);
  });
}

export const updateUserDataRequest = (dispatch, userData, callback) => {
  let targetUrl = baseURL.concat('user');
  const url = new URL(targetUrl)
  fetch(url, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
    body: JSON.stringify({type: "user_info", data: userData})
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    if(json.hasOwnProperty("error")){
      callback({status: "error", message: json.error})
    }else{
      callback(json.data, {status: "succsess", message: "Дані збережено"})
    }
  })
  .catch((error) => {
     console.error(error);
  });
}

export const updateUserContactInfoRequest = (dispatch, userData, callback) => {
  let targetUrl = baseURL.concat('user');
  const url = new URL(targetUrl)

  fetch(url, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
    body: JSON.stringify({type: "contact_info", data: userData})
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    if(json.hasOwnProperty("error")){
      callback({status: "error", message: json.error})
    }else{
      callback(json.data, {status: "succsess", message: "Дані збережено"})
    }

  })
  .catch((error) => {
    debugger
     console.error(error);
  });
}

export const plaseOrderRequest = (dispatch, orderDetails, callback) => {
  let targetUrl = baseURL.concat('orders');
  const url = new URL(targetUrl)
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
    body: JSON.stringify({type: "Place_order", data: orderDetails})
  })
  .then((response) => {
      return response.json()
  })
  .then((json) => {
    dispatch(callback())
  })
  .catch((error) => {
     console.error(error);
  });
}

export const getOrdersRequest = (userId, callback) => {
  const urlParams = {UserId: userId}
  makeFetchRequest("get", "orders", callback, urlParams)
}
